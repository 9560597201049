import { ApolloClient, InMemoryCache } from '@apollo/client'
// import { StrictTypedTypePolicies } from './generated/helpers'

const Fields = {
  date: (value: string) => new Date(value)
}

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Customer: {
        keyFields: ['uuid']
      },
      DirectDepositNotification: {
        fields: {
          lastContactedUtc: {
            read: Fields.date
          }
        }
      }
    } // as StrictTypedTypePolicies
  }),
  uri: '/graphql'
})
