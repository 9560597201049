import * as React from 'react'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import { Currency } from '@toasttab/buffet-pui-types'

export interface BuffetContext {
  locale?: Locale
  currency?: Currency
}

export interface BuffetContextProviderProps extends BuffetContext {
  children: React.ReactNode
}

export const BuffetStateContext = React.createContext<BuffetContext>({})

type BuffetContextOutput = {
  currency: Currency
  locale: Locale
}

const defaults: BuffetContextOutput = {
  currency: Currency.USD,
  locale: getLocale()
}

/**
 * @deprecated - use Locale Utilities' getLocale method if needed, or obtain currency value from Restaurant info
 * BuffetContextProvider - Provides context for components and utilities
 * @param {Locale?} locale - A string representing a locale, of type Locale
 * @param {Currency?} currency - A string representing a currency value, of type Currency
 * @param {React.ReactNode} children - Children components
 */
export const BuffetContextProvider = ({
  locale,
  currency,
  children
}: BuffetContextProviderProps) => {
  return (
    <BuffetStateContext.Provider
      value={React.useMemo(
        () => ({
          locale: locale || getLocale(),
          currency: currency || defaults.currency
        }),
        [locale, currency]
      )}
    >
      {children}
    </BuffetStateContext.Provider>
  )
}

export function useBuffetContext(): BuffetContextOutput {
  const context = React.useContext(BuffetStateContext)
  return {
    currency: context.currency || defaults.currency,
    locale: context.locale || getLocale()
  }
}
