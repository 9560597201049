export const buildId = (...args: any[]): string =>
  args
    .reduce(
      (acc, arg) =>
        arg != null
          ? acc.concat(arg.toString().toLowerCase().split(/\W+/).filter(String))
          : acc,
      []
    )
    .join('-')
