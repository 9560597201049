import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import { Options } from './'
import { format, formatPercentAbbreviated } from './utils'

type NumberFn = (
  number: number | string,
  localeOrOptions?: Locale | Options,
  options?: Options
) => string

/**
 * formatPercent - Formats a fraction into percentage string, such as 0.25 to 25%
 * @param {number | string} fraction - A string or number representing a fraction of 1 such as 0.25 (25%) or 1.5 (150%)
 * @param {Locale | Options} localeOrOptions - A locale (string) such as `en-US`, or options
 * @param {Options} options - Options to control precision, rounding and abbreviation
 */
export const formatPercent: NumberFn = (
  fraction,
  localeOrOptions,
  options = {}
) => {
  let locale = getLocale()
  if (typeof localeOrOptions === 'string') {
    locale = localeOrOptions
  }
  if (typeof localeOrOptions === 'object') {
    options = localeOrOptions
  }

  const { precision, abbreviated, trailingZeros } = options
  fraction = typeof fraction === 'string' ? Number(fraction) : fraction
  if (abbreviated) {
    return formatPercentAbbreviated(fraction, locale, options)
  }

  const intlOptions: Intl.NumberFormatOptions = {
    style: 'percent'
  }
  if (typeof precision === 'number') {
    intlOptions.minimumFractionDigits = trailingZeros ? precision : 0
    intlOptions.maximumFractionDigits = precision
  }
  return format(fraction, locale, intlOptions)
}

type CreateNumberFormatter = (
  localeOverride?: Locale,
  options?: Options
) => (number: number | string) => string

/**
 * createFormatPercent - Returns a function that will format a fraction into percentage string
 * @param {Locale} localeOverride - A locale (string) such as `en-US`
 * @param {Options} options - Options to control precision, rounding and abbreviation
 */
export const createFormatPercent: CreateNumberFormatter =
  (localeOverride, options) => (number) =>
    formatPercent(number, localeOverride || getLocale(), options)
