import React from 'react'
import { Cell } from '@toasttab/buffet-pui-table'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { SplitDepositType } from '@local/grouter'
import {
  Currency,
  formatCurrency,
  formatPercent
} from '@toasttab/buffet-pui-number-utilities'
import { t } from '@local/translations'

type AmountCellProps = {
  amount: number
  splitDepositType: SplitDepositType
  isLast?: boolean
}

export const AmountCell: React.FunctionComponent<AmountCellProps> = ({
  amount,
  splitDepositType,
  isLast = false
}) => {
  const formatAmount = useFormatAmount()
  return (
    <Cell className='align-middle'>
      {formatAmount(amount, splitDepositType, isLast)}
    </Cell>
  )
}

export const useFormatAmount = () => {
  const { locale } = useBuffetContext()
  const isRoundNumber = (amount: number) => amount % 1 === 0
  return (
    amount: number,
    splitDepositType: SplitDepositType,
    isLast?: boolean
  ) => {
    return splitDepositType === 'DOLLAR' && isLast
      ? t('direct-deposits.table.amount.remainder')
      : splitDepositType === 'DOLLAR'
      ? formatCurrency(
          { amount: amount, currency: Currency.USD },
          {
            trailingZeros: !isRoundNumber(amount)
          }
        )
      : formatPercent(amount / 100, locale)
  }
}
