import * as React from 'react'
import { EmptyStateCard } from '@local/empty-state-card'
import { t } from '@local/translations'

export const EmptyStateNoDirectDeposits: React.FunctionComponent = () => {
  return (
    <EmptyStateCard
      subtitle={t(
        'direct-deposit-incomplete.empty-state.no-employees-without-direct-deposit'
      )}
    />
  )
}
