import React from 'react'

import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import { Cell } from '@toasttab/buffet-pui-table'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MoreVertIcon } from '@toasttab/buffet-pui-icons'
import {
  useNavigateToLegacyDirectDepositLink,
  useUser
} from '@toasttab/ec-session'

import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { isValidEmail } from '../utils'

import { trackIncomplete } from '@local/track'
import { EmailSendModal } from '../../../../email-send-modal'
import { t } from '@local/translations'

type ActionCellProps = {
  employee: IncompleteDirectDepositsQueryEmployeeFragment
}

export const ActionsCell: React.FunctionComponent<ActionCellProps> = ({
  employee
}) => {
  const isEmailValid = isValidEmail(employee.email)
  const client = useUser().client
  const [isEmailSendModalOpen, setIsEmailSendModalOpen] = React.useState(false)
  const onOpenEmailSendModal = React.useCallback(
    () => setIsEmailSendModalOpen(true),
    []
  )
  const onCloseEmailSendModal = React.useCallback(
    () => setIsEmailSendModalOpen(false),
    []
  )

  const navigateToLegacyDirectDepositPage =
    useNavigateToLegacyDirectDepositLink(employee.id)

  return (
    <>
      {isEmailSendModalOpen && (
        <EmailSendModal onClose={onCloseEmailSendModal} employee={employee} />
      )}
      <Cell className='border-default border-b md:border-none md:flex md:place-content-center'>
        <MenuDropdown
          placement='bottom-end'
          renderToggle={(props: any) => (
            <IconButton
              icon={
                <MoreVertIcon
                  aria-label={t('direct-deposit-incomplete.table.action.label')}
                />
              }
              {...props}
            />
          )}
        >
          {isEmailValid && (
            <ListItem
              {...trackIncomplete('send-single-email')}
              label={t('direct-deposit-incomplete.table.action.email')}
              onClick={onOpenEmailSendModal}
            />
          )}
          {!isEmailValid && (
            <ListItem
              {...trackIncomplete('update-email')}
              label={t('direct-deposit-incomplete.table.action.email-update')}
              onClick={() =>
                window.location.assign(
                  `/${client}/employees/${employee.id}/account`
                )
              }
            />
          )}
          <ListItem
            {...trackIncomplete('manually-add-direct-deposit')}
            label={t('direct-deposit-incomplete.table.action.enter-manually')}
            onClick={() => navigateToLegacyDirectDepositPage()}
          />
        </MenuDropdown>
      </Cell>
    </>
  )
}
