import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { Options } from '../'
import { isNumber } from '../isNumber'

export const formatPercentAbbreviated = (
  fraction: number,
  locale: Locale,
  options: Options
) => {
  // Note: Locale is not yet supported for browser compatibility with Chrome 24+
  // Revisit this constraint to then use `NumberFormat` `notation` option in future
  if (!isNumber(fraction)) {
    return 'NaN'
  }

  const { precision, trailingZeros } = options

  const intlOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: isNumber(precision) ? precision : 2
  }

  const number = fraction * 100

  const NUMBER_ABBREVIATION_SUFFIXES = ['', 'K', 'M', 'B', 'T', 'q', 'Q']
  const MIN_VALUE_FOR_ABBREVIATION = 1000
  const tier = (Math.log10(Math.abs(number)) / 3) | 0

  // if tier zero or less than min required value for abbreviation, we don't need a suffix
  if (
    tier === 0 ||
    !NUMBER_ABBREVIATION_SUFFIXES[tier] ||
    number < MIN_VALUE_FOR_ABBREVIATION
  ) {
    // eslint-disable-next-line @toasttab/buffet/hard-coded-currencies
    return `${Intl.NumberFormat(locale, intlOptions).format(number)}%`
  }

  // get suffix and determine scale
  const suffix = NUMBER_ABBREVIATION_SUFFIXES[tier]
  const scale = Math.pow(10, tier * 3)

  // scale and format
  const scaledNumber = number / scale
  const scaledNumberLength = scaledNumber.toString().split('.')[0].length
  if (precision && trailingZeros) {
    intlOptions.minimumSignificantDigits = precision + scaledNumberLength
    intlOptions.maximumSignificantDigits = precision + scaledNumberLength
  }

  // eslint-disable-next-line @toasttab/buffet/hard-coded-currencies
  const scaledNumberString = Intl.NumberFormat(locale, {
    ...intlOptions
  }).format(scaledNumber)

  return `${scaledNumberString}${suffix}%`
}
