import * as React from 'react'

import { Badge } from '@toasttab/buffet-pui-badge'
import { Cell } from '@toasttab/buffet-pui-table'

import { IncompleteDirectDepositsQueryNotificationFragment } from '@local/grouter'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { t } from '@local/translations'

type Props = {
  currentEmail: string
  notification?: IncompleteDirectDepositsQueryNotificationFragment | null
}

export const LastEmailedCell: React.FunctionComponent<Props> = (props) => {
  return (
    <Cell className='align-middle text-nowrap overflow-hidden'>
      <LastEmailedComponent {...props} />
    </Cell>
  )
}

export const LastEmailedComponent: React.FunctionComponent<Props> = (props) => {
  const screenSize = useScreenSize()

  const { currentEmail, notification } = props
  const formattedDate =
    notification?.lastContactedUtc &&
    format(notification?.lastContactedUtc, Formats.date.short)
  const formattedTime =
    notification?.lastContactedUtc &&
    format(notification?.lastContactedUtc, Formats.time.short)

  const successfullySent =
    notification?.status === 'SUCCESSFULLY_SENT' &&
    currentEmail === notification.email

  return (
    <>
      <EmailChangedBadge
        formattedDate={formattedDate}
        formattedTime={formattedTime}
        {...props}
      />

      <FailureToSendBadge
        formattedDate={formattedDate}
        formattedTime={formattedTime}
        {...props}
      />

      {successfullySent &&
        t('direct-deposit-incomplete.table.last-contacted.success', {
          date: formattedDate,
          time: formattedTime
        })}

      {notification === null &&
        screenSize < ScreenSize.MD &&
        t('direct-deposit-incomplete.table.last-contacted.not-contacted')}
    </>
  )
}

type BadgeProps = {
  formattedDate: string | null | undefined
  formattedTime: string | null | undefined
} & Props

const EmailChangedBadge: React.FunctionComponent<BadgeProps> = ({
  currentEmail,
  formattedDate,
  formattedTime,
  notification
}) => {
  const emailChanged = !!notification && notification.email !== currentEmail
  const successfullySent = notification?.status === 'SUCCESSFULLY_SENT'

  return (
    emailChanged && (
      <Tooltip
        content={() =>
          formattedDate &&
          formattedTime && (
            <>
              {t(
                `direct-deposit-incomplete.table.last-contacted.email-changed.${
                  successfullySent ? 'success' : 'failed'
                }.tooltip`,
                {
                  date: formattedDate,
                  time: formattedTime,
                  email: notification.email
                }
              )}
            </>
          )
        }
        placement='bottom'
      >
        <Badge color='warning' size='sm'>
          {t('direct-deposit-incomplete.table.last-contacted.email-changed')}
        </Badge>
      </Tooltip>
    )
  )
}

const FailureToSendBadge: React.FunctionComponent<BadgeProps> = ({
  currentEmail,
  formattedDate,
  formattedTime,
  notification
}) => {
  const failedToSend =
    notification?.status === 'FAILURE_TO_SEND' &&
    currentEmail === notification.email

  return (
    failedToSend && (
      <Tooltip
        content={() =>
          formattedDate &&
          formattedTime && (
            <>
              {t(
                'direct-deposit-incomplete.table.last-contacted.failed-to-send.tooltip',
                { date: formattedDate, time: formattedTime }
              )}
            </>
          )
        }
        placement='bottom'
      >
        <Badge color='warning'>
          {t('direct-deposit-incomplete.table.last-contacted.failed-to-send')}
        </Badge>
      </Tooltip>
    )
  )
}
