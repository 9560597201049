import * as React from 'react'
import cx from 'classnames'
import { buildId, useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { RadioButton, RadioButtonProps } from '../RadioButton'
import { LabeledHelperTextProps } from '@toasttab/buffet-pui-text-base'
import {
  SelectionGroup,
  SelectionGroupProps,
  inlineSelectionItemsClasses
} from '@toasttab/buffet-pui-input-base'

export interface RadioGroupProps
  extends TestIdentifiable,
    LabeledHelperTextProps,
    Omit<SelectionGroupProps, 'children' | 'onChange'> {
  onChange?: RadioButtonProps['onChange']
  inline?: boolean
  // @deprecated Using the `label` prop will an accessible `legend` to the group. If you need to use text external to the group use `aria-labelledbyid` instead.
  labelledbyId?: string
  options?: {
    value: string
    subLabel?: string
    label: string
    disabled?: boolean
    checked?: boolean
  }[]
}

let radioGroupCount = 1

export const RadioGroup = (props: RadioGroupProps) => {
  const {
    options = [],
    disabled,
    testId,
    onChange = () => undefined,
    inline,
    itemsContainerClassName,
    name = buildId('radio-group-', radioGroupCount++),
    ...otherProps
  } = props

  const derivedTestId = useUniqueId(testId, 'radio-group-')
  const derivedDisabled = options.every((option) => option.disabled) || disabled
  return (
    <SelectionGroup
      disabled={derivedDisabled}
      {...otherProps}
      itemsContainerClassName={cx(
        'my-2',
        inline && inlineSelectionItemsClasses,
        itemsContainerClassName
      )}
      testId={derivedTestId}
    >
      {options.map((op) => (
        <RadioButton
          name={name}
          label={op.label}
          subLabel={op.subLabel}
          value={op.value}
          checked={op.checked}
          disabled={op.disabled || disabled}
          key={op.value}
          onChange={onChange}
          containerClassName={cx(inline && 'mr-8')}
        />
      ))}
    </SelectionGroup>
  )
}
